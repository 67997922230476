import React, { useRef, useState } from 'react'
import TextEditor from '../../Components/TextEditor/TextEditor'
import axios from 'axios';
import './CreateArticle.scss'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';

const CreateArticle = () => {

    const store = useStore()

    // const [content, setContent] = useState('');
    const [articleType, setArticleType] = useState('')
    const [articleCategory, setArticleCategory] = useState('')
    const [error, setError] = useState(false)
    // const [title, setTitle] = useState('')
    // const [descr, setDescr] = useState('')
    const [image, setImage] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [showName, setShowName] = useState()
    const [formData, setFormData] = useState({
        content: '',
        descr: '',
        title: '',
        name: '',
        surname: '',
    })


    const imageInput = useRef()


    const handleState = (e) => {
        // console.log(e.target)
        const { name, value } = e.target

        setFormData({ ...formData, [name]: value })
    }

    const handleContentChange = (newContent) => {
        setFormData(prevFormData => ({ ...prevFormData, content: newContent }));
    };


    const handleTypeCheckbox = (option) => {
        setArticleType(option);
        setArticleCategory('')
    };

    const handleCategoryCheckbox = (option) => {
        setArticleCategory(option)
        setError(false)
    }



    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const convertImage = async () => {
        const base64 = await convertToBase64(imageInput?.current?.files[0]);
        setImage(base64)
        // setFormData({ ...formData, image: base64 });
    }


    const addArticle = () => {
        axios.post('/article/article', {
            image: image,
            title: formData.title,
            descr: formData.descr,
            content: formData.content,
            type: articleType,
            category: articleCategory,
            name: formData.name,
            surname: formData.surname,
            showName: showName
        }).then(({ data }) => {
            setShowName(false)
            setFormData({
                content: '',
                descr: '',
                title: '',
                name: '',
                surname: '',

            })
            setArticleCategory('')
            setArticleType('')
            setOnSuccess(true)

        }).catch((err) => {

        })

        setTimeout(() => {
            setOnSuccess(false)
        }, 5000);
    }
    // const [name, setName] = useState('');

    // const [surname, setSurname] = useState('');
    const handleShowName = () => {
        setShowName(prev => !prev)
    }
    return (
        <div className='create-article-page'>

            <h1>Create Article</h1>
            <form action="">

                <input type="text" name="title" onChange={(e) => handleState(e)} placeholder='Input Title' />

                <input type="text" name="descr" onChange={(e) => handleState(e)} placeholder='Input Short Description' />

                {store.auth.userInfo.role === "admin" && <div>
                    <input type="text" name='name' onChange={(e) => handleState(e)} placeholder='Input creator name' />
                    <input type="text" name='surname' onChange={(e) => handleState(e)} placeholder='Input creator surname' />
                    <input
                        type="checkbox"
                        checked={showName}
                        onChange={handleShowName}
                    /> show name
                </div>}


                <input className='image-upload' type='file' ref={imageInput} onInput={() => convertImage()} placeholder='Upload main image'></input>

            </form>
            <div className='text-editor'>
                <TextEditor value={formData.content} onChange={handleContentChange} />
            </div>
            {/* {content} */}

            <div >
                <div className='type'>
                    <label>
                        <input
                            type="radio"
                            checked={articleType === 'rough'}
                            onChange={() => handleTypeCheckbox('rough')}
                        />
                        rough
                    </label>
                    <label>
                        <input
                            type="radio"
                            checked={articleType === 'polished'}
                            onChange={() => handleTypeCheckbox('polished')}
                        />
                        polished
                    </label>
                </div>
                <div className='category'>
                    {articleType === "polished" ?
                        <div>
                            <label><input type='radio' checked={articleCategory === "you need to know"} onChange={() => handleCategoryCheckbox("you need to know")} />you need to know</label>
                            <label><input type='radio' checked={articleCategory === "right-hand man"} onChange={() => handleCategoryCheckbox("right-hand man")} />right-hand man</label>
                            <label><input type='radio' checked={articleCategory === "gemspedia"} onChange={() => handleCategoryCheckbox("gemspedia")} />gemspedia</label>
                            <label><input type='radio' checked={articleCategory === "crystall ball"} onChange={() => handleCategoryCheckbox("crystall ball")} />crystall ball</label>
                            <label><input type='radio' checked={articleCategory === "congenial"} onChange={() => handleCategoryCheckbox("congenial")} />congenial</label>
                        </div>
                        :
                        <div>
                            <input type='radio' checked={articleCategory === "food for thought"} onChange={() => handleCategoryCheckbox("food for thought")} />food for thought
                            <input type='radio' checked={articleCategory === "market talks"} onChange={() => handleCategoryCheckbox("market talks")} />market talks
                            <input type='radio' checked={articleCategory === "big time"} onChange={() => handleCategoryCheckbox("big time")} />big time
                        </div>
                    }
                </div>
                {(!error && articleType && articleCategory) && <button onClick={addArticle}>Publish Article</button>}
                {onSuccess && <div className='success'>Article successfully uploaded</div>}
                {(articleType === '' && error) && <div>Choose type of article</div>}
                {(articleCategory === '' && error) && <div>Choose category of article</div>}
                {/* <button onClick={addArticle}>add Article to polished</button> */}
            </div>


        </div >
    )
}

export default observer(CreateArticle)