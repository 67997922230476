import { makeAutoObservable } from "mobx"

class Article {
    articles = []

    constructor() {
        makeAutoObservable(this)
    }

    getArticles(searchData) {
        this.articles = searchData
    }
}

export default Article