import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'

import './UserSettings.scss'
import axios from 'axios'
import { Link } from 'react-router-dom'

const UserSettings = () => {
    // const [isShow, setIsShow] = useState('')
    const [isChangeName, setIsChangeName] = useState(false)
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const store = useStore()


    const changeName = async (event) => {
        event.preventDefault();
        setError('')

        if (name.length < 2 || surname.length < 2) {
            return setError('Name is too short!')
            // return
        }

        axios.patch('/user/resetname', {
            newName: name,
            newSurname: surname,
            email: store.auth.userInfo.email
        }).then(({ data }) => {
            setIsChangeName(false)
        }).catch((err) => {
            console.log(err)
            setError(err?.response?.data?.message)
        }).finally(() => {


        })
    }

    const handleNameChanger = (e) => {
        const { name, value } = e.target
        if (name === "name") {
            setName(value)
        } else if (name === "surname") {
            setSurname(value)
        }
        // if(e.target.name)

    }

    return (
        <div className='users-page'>


            <div className="user-wrapper">
                <div className="user-container">
                    <div className='main-title'>Account</div>

                    <div className="name-container">
                        <div className="name">
                            {isChangeName ?
                                <div className='change-name'>
                                    <div className='title'>First Name</div>
                                    <div className='input-controls'>
                                        <input type="text" name='name' onChange={(e) => handleNameChanger(e)} />
                                        <div className='btn-done' onClick={() => setIsChangeName(false)}>
                                            <span className="material-symbols-outlined">
                                                close
                                            </span>
                                        </div>
                                        <div className='btn-cancel' onClick={(e) => { changeName(e); setError('') }}>
                                            <span className="material-symbols-outlined">
                                                done
                                            </span>
                                        </div>
                                    </div>
                                    <div className='title'>Last Name</div>
                                    <input type="text" name='surname' onChange={(e) => handleNameChanger(e)} />
                                    <div>{error}</div>
                                </div>
                                :
                                <div>
                                    <div className='title'>Name</div>
                                    {/* <div className='prev-name'> {store.auth.userInfo.name} {store.auth.userInfo.surname}</div> */}
                                    <div className='prev-name'>  {(!name && !surname) ? `${store.auth.userInfo.name} ${store.auth.userInfo.surname}` : `${name} ${surname}`}</div>
                                </div>}
                        </div>
                        <button className='btn first' onClick={() => { setIsChangeName(prev => !prev); setError('') }}>Change Name</button>
                    </div>
                    <div className='title'>Email</div>
                    <div className="email-container">
                        <div className="email">
                            {store.auth.userInfo.email}
                        </div>
                        <Link to={'/settings/email'} className='btn'>Change Email</Link>
                    </div>
                    <div className='title'>Password</div>
                    <div className="password-container">
                        <div className="password">
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                            <span className="material-symbols-outlined">
                                emergency
                            </span>
                        </div>
                        <Link to={'/settings/password'} className='btn'>Change Password</Link>
                    </div>

                    <div className='signout' onClick={() => store.auth.logout()}>Sign out</div>

                </div>
            </div>
            {store.auth.userInfo.role === 'publisher' &&
                <div className="publisher-wrapper">
                    <div className='main-title'>Publisher</div>
                    <Link to={'/createarticle'} className='btn link'>Create article</Link>
                    <Link to={'/addvideo'} className='btn link'>Add video</Link>
                    <Link to={'/createexternal'} className='btn link'>Add external article</Link>
                    <div className='signout mobile' onClick={() => store.auth.logout()}>Sign out</div>
                </div>

            }
        </div>
    )
}

export default observer(UserSettings)