import { makeAutoObservable } from 'mobx';
import axios from 'axios';

class ErrorHandlingStore {
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setupErrorHandling() {

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const errorMessage =
                    error?.response?.data?.error ||
                    (error?.response.status === 404 ? 'Error 404' : 'An unknown error occurred');

                this.setError(errorMessage);

                return Promise.reject(error);
            }
        );
    }

    setError(error) {
        this.error = error;
    }

    clearError() {
        this.error = null;
    }
}

const errorHandlingStore = new ErrorHandlingStore();

export default errorHandlingStore;
