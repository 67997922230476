import React, { useEffect, useState } from 'react'
import MulitCarousel from '../MulitCarousel/MulitCarousel'
import './MainContentBlock.scss'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { imgPath, textCutter } from '../../utils'


const MainContentBlock = ({ blockName, articles }) => {

    // const [articles, setArticles] = useState([])
    const [carouselData, setCarouselData] = useState([])
    const [popularArticles, setPopularArticles] = useState([])
    const [featuredArticle, setFeaturedArticle] = useState([])
    const [videos, setVideos] = useState([])
    const [external, setExternal] = useState([])

    useEffect(() => {
        const fetchCarouselThumb = async () => {
            try {
                const { data } = await axios.get("/article/articles", {
                    params: {
                        type: blockName,
                        limit: 7,
                        contentType: 'article'
                    }
                })
                setCarouselData(data)
            } catch (e) {
                console.log(e)
            }
        }
        const fetchPopularArticles = async () => {
            try {
                const { data } = await axios.get('/article/articles', {
                    params: {
                        type: blockName,
                        limit: 2,
                        sortPopular: true,
                        contentType: 'article'
                    }
                })
                setPopularArticles(data)
            } catch (e) {
                console.log(e)
            }

        }
        const fetchFeaturedArticle = async () => {
            try {
                const { data } = await axios.get('/article/articles', {
                    params: {
                        featured: true,
                        limit: 1,
                        contentType: 'article',
                        type: blockName
                    }
                })

                setFeaturedArticle(data)
            } catch (e) {
                console.log(e)
            }
        }

        const fetchVideo = async () => {
            try {
                const { data } = await axios.get('/article/articles', {
                    params: {
                        type: blockName,
                        limit: 3,
                        contentType: 'video'

                    }
                })
                setVideos(data)
            } catch (e) {
                console.log(e)
            }
        }

        const fetchExternal = async () => {
            try {
                const { data } = await axios.get('/article/external', {
                    params: {
                        type: blockName,
                        limit: 2
                    }
                })

                setExternal(data)
            } catch (e) {
                console.log(e)
            }
        }

        fetchExternal()
        fetchVideo()
        fetchCarouselThumb()
        fetchFeaturedArticle()
        fetchPopularArticles()
    }, [])


    return (
        <div className='block-component'>
            <div className='carousel'>
                <div className='title'>
                    <Link to={`/articles/${blockName}/latest`}> <h2>Latest<span>&gt;</span></h2></Link>
                    <h1>{blockName.charAt(0).toUpperCase() + blockName.slice(1).toLocaleLowerCase()}</h1>
                </div>
                <MulitCarousel carouselData={carouselData} />
            </div>

            <div className='main-content'>
                <div className='featured'>
                    <h2>
                        <Link to={`/article/${featuredArticle.at(0)?.link}`}>
                            Featured<span>&gt;</span>
                        </Link>
                    </h2>
                    {featuredArticle?.map((item) => (
                        <Link to={`/article/${item.link}`} key={item._id}>
                            <img src={imgPath(item.thumb)} alt={item.link} />
                            <div className='container'>
                                <div className='title'> {item.title}</div>
                                <div className="descr">{item.description}</div>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="popular">
                    <h2>
                        <Link to={`/articles/${blockName}/popular`}>Popular<span>&gt;</span></Link>
                    </h2>
                    {/* <Link to={`/articles/${blockName}/popular`}>
                        <h2>Popular<span>&gt;</span></h2>
                    </Link> */}

                    <div className='container'>
                        {popularArticles?.map((item) => (
                            <Link to={`/article/${item.link}`} key={item._id}>
                                <img src={imgPath(item.thumb)} alt={item.link} />
                                <div className="title">{item.title}</div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className='bottom-content'>
                {<div className="videos">
                    <h2>
                        <Link to={`/articles/${blockName}/video`}>Videos<span>&gt;</span>
                        </Link>
                    </h2>
                    <div className="container">
                        {videos?.map((item) => (
                            <Link to={`/article/${item.link}`} key={item._id}>
                                <img src={item.thumb} />
                                <div className="title">
                                    {item.title}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>}
                <div className="external">
                    <h2>
                        <Link to={`/externallist/${blockName}`}>External Articles<span>&gt;</span>
                        </Link>
                    </h2>
                    <div className="container">
                        {external?.map((item) => (
                            <div className='wrapper' key={item._id}>
                                <div className="source">{item.content}</div>
                                <div className="title">{item.title}</div>
                                <div className="description">{item.description}</div>
                                <a href={`${item.link}`} className="link">Read<span className="material-symbols-outlined">
                                    open_in_new
                                </span></a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainContentBlock