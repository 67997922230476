import React from 'react'
import './ErrorPage.scss'

const ErrorPage = () => {
    return (
        <div className='errorPage'>
            <p className='errorMessage'>Sorry, we couldn't find any content matching your request.</p>
        </div>
    )
}

export default ErrorPage