

import React from 'react'

import { Link } from 'react-router-dom'

import { imgPath } from '../../utils'

import moment from 'moment'
import './List.scss'
import ErrorPage from '../../Pages/ErrorPage/ErrorPage'

const List = ({ articles, hasMoreArticles, showMoreArticles }) => {


    return (
        <div className='list-component'>
            {articles?.length ?
                <div className='list-wrapper'>
                    {(articles?.map((item) => (
                        <div key={item._id} className='article' >
                            <div className="article-container">

                                <Link to={`/article/${item.link}`}>
                                    {item.contentType === 'article' ?
                                        <img src={imgPath(item.thumb)} alt="" />
                                        :
                                        <img src={item.thumb} alt='' />
                                    }
                                </Link>
                                <div>
                                    <Link to={`/article/${item.link}`}>
                                        <h1>
                                            {item.title}
                                        </h1>
                                    </Link>
                                    <div className='date'>
                                        {moment(item.createdAt).format("L")}
                                    </div>
                                    <div className='descr'>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                            <div className="divider" />
                        </div>
                    )))}
                    {(articles.length && hasMoreArticles) ? <button className='fetch-articles' onClick={showMoreArticles}>Show more</button> : null}

                </div>
                :
                <div>{<ErrorPage />}</div>}
        </div>
    )
}

export default List