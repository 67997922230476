import axios from 'axios';
import React, { useCallback, useState } from 'react'
import useDebaunce from '../../hooks/useDebaunce'
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';


const SearchBar = () => {
    const store = useStore()
    const [articles, setArticles] = useState([]);
    const [inputString, setInputString] = useState('')

    const getArticles = useCallback(async () => {
        try {
            if (inputString.length === 0) {
                setArticles([])
                return
            }
            const { data } = await axios.get('/article/search', {
                params: { title: inputString }
            })
            setArticles(data)
            store.article.getArticles(data)
        } catch (e) {
            console.log(e)
        }
    }, [inputString])

    const handleInput = () => {
        setInputString("")
    }

    useDebaunce(getArticles, 500)

    return (
        <div className='search-component'>
            <input onChange={(e) => setInputString(e.target.value)} value={inputString} placeholder='Search Articles' type="text" />
            <div className="list">
                {articles?.map((item) => (
                    <Link to={`/search/${inputString}`} key={item._id} onClick={handleInput}>
                        <div>{item.title}{item.description}</div>
                    </Link>
                ))}
            </div>
        </div >
    )
}

export default observer(SearchBar)