import axios from 'axios'
import React, { useState } from 'react'
import './CreateExternalArticle.scss'

const CreateExternalArticle = () => {

    const [type, setType] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)
    const [formData, setFormData] = useState({
        title: '',
        link: '',
        description: '',
        source: '',
        // type: ''
    })

    const handleState = (e) => {
        // console.log(e.target)
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleTypeCheckbox = (option) => {
        setType(option);


    };


    // const postArticle = async () => {
    //     try {
    //         await axios.post('/article/external', {
    //             title: formData.title,
    //             link: formData.link,
    //             description: formData.description,
    //             source: formData.source,
    //             type: type
    //         })

    //     } catch (e) {
    //     }
    // }

    const postArticle = () => {
        axios.post('/article/external', {
            title: formData.title,
            link: formData.link,
            description: formData.description,
            source: formData.source,
            type: type
        }).then(({ data }) => {
            setFormData({
                title: '',
                link: '',
                description: '',
                source: '',
            });
            setOnSuccess(true)
        }).catch((err) => {

        })

        setTimeout(() => {
            setOnSuccess(false)
        }, 5000);
    }

    return (
        <div className='create-external-page'>
            <h1>Add external article</h1>
            <div className="input-container">
                <input type="text" name='title' onChange={(e) => handleState(e)} placeholder='Title' />
                <input type="text" name='description' onChange={(e) => handleState(e)} placeholder='Description' />
                <input type="text" name='source' onChange={(e) => handleState(e)} placeholder='Source name' />
                <input type="text" name='link' onChange={(e) => handleState(e)} placeholder='Link' />
            </div>
            <div className='type'>
                <label>
                    <input
                        type="radio"
                        checked={type === 'rough'}
                        onChange={() => handleTypeCheckbox('rough')}
                    />
                    rough
                </label>
                <label>
                    <input
                        type="radio"
                        checked={type === 'polished'}
                        onChange={() => handleTypeCheckbox('polished')}
                    />
                    polished
                </label>
            </div>
            <button onClick={postArticle}>Post</button>
            {onSuccess && <div className='success'>Article successfully uploaded</div>}
        </div>
    )
}

export default CreateExternalArticle








