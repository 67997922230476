import React from 'react'
import './Popup.scss'

const Popup = ({ _id, deleteComment, setShowPopup }) => {

    const handleDelete = () => {
        // console.log(id)
        deleteComment(_id)
        setShowPopup(false)
    }

    return (
        <div className="popup">
            <div className="popup-content">
                <p>Are you sure you want to delete this comment?</p>
                <div className="popup-buttons">
                    <button onClick={handleDelete}>Yes</button>
                    <button onClick={() => setShowPopup(false)}>No</button>
                </div>
            </div>
        </div>
    );
}

export default Popup