import React from 'react'
import './PrivacyAndTerms.scss'

const ContactUs = () => {
    return (
        <div className='contact-us'>
            <h1>Contact us</h1>
            <div>Contact us</div>
            <a href="mailto:contact@rough-polished.com">contact@rough-polished.com</a>
            <div>Customer support</div>
            <a href="mailto:support@rough-polished.com">support@rough-polished.com</a>
        </div>
    )
}

export default ContactUs

