import axios from 'axios';
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import './Auth.scss'
import { Link } from 'react-router-dom';

const Auth = () => {
    const store = useStore()


    // const [signType, setSignType] = useState('login');
    const [signType, setSignType] = useState('login');
    const [error, setError] = useState('');
    const [stop, setStop] = useState(false)
    const [inputData, setInputData] = useState({
        email: '',
        password: '',
        rPassword: '',
        name: '',
        surname: '',
        code: ''
    })






    const register = async (event) => {
        event.preventDefault();
        // setError('')


        axios.post('/auth/register', {
            email: inputData.email,
            password: inputData.password,
            name: inputData.name,
            surname: inputData.surname
        }).then(({ data }) => {

            setSignType('code')
        }).catch((err) => {
            // console.log(err)
            // setError(err?.response?.data?.error)
        }).finally(() => {


        })

    }

    const verifyEmail = async (event) => {
        event.preventDefault();
        // setError('')

        axios.patch('/auth/verify', {

            email: inputData.email,
            code: inputData.code

        }).then(({ data }) => {
            if (data.token) {
                store.auth.authorize(data.token)
            }

        }).catch((err) => {
            console.log(err)
            // setError(err?.response?.data?.error)
        })
    }


    const preventFunction = () => {
        setTimeout(() => {


            setStop(false)
        }, 120000);
    }

    const generateNewCode = async (event) => {
        event.preventDefault();
        setStop(true)
        preventFunction()
        // setError('')
        axios.patch('/auth/getcode', {
            email: inputData.email
        }).then(({ data }) => {

            setSignType("recoverCode");
        }).catch((err) => {
            console.log(err)
            // setError(err?.response?.data?.error)
        })

    }

    const login = async (event) => {
        event.preventDefault();
        // setError("")
        axios.post('/auth/login', {
            email: inputData.email,
            password: inputData.password
        }).then(({ data }) => {
            store.auth.authorize(data.token)
        }).catch((err) => {
            console.log(err)
            // setError(err?.response?.data?.error)
        })
    }

    const changePassword = async (event) => {
        event.preventDefault();
        // setError('')
        axios.post('/auth/resetpass', {
            email: inputData.email,
            newPassword: inputData.password,
            code: inputData.code
        }).then(({ data }) => {

            setSignType('login')
        }).catch((err) => {
            // setError(err?.response?.data?.error)
            setSignType('recoverCode')
        }).finally(() => {

        })
    }



    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value })
    }




    return (
        <div className='auth-page'>
            <h1>Rough-Polished</h1>

            {signType === "login" && (
                <div className='wrapper'>
                    <form action="" >
                        <div className="input-title"></div>
                        <div className='input-field'>
                            <input type="email" name='email' placeholder=' E-mail' onInput={(event) => handleInputChange(event)} required />
                            <input type="password" name='password' placeholder=' Password' onInput={(event) => handleInputChange(event)} required />
                            <div className='additional key' onClick={() => setSignType("recover")}>forgot you password?</div>
                        </div>
                        <div className='main key' onClick={login}>login</div>
                        <div className='additional key' onClick={() => setSignType("register")}>new user? sign up</div>
                    </form>
                </div>
            )}
            {signType === "register" && (
                <div className='wrapper'>
                    <form action="" /* onSubmit={register} */ >
                        {/* <div className='error'>{error ? error : null}</div> */}
                        <div className="input-title">{error}</div>
                        <div className='input-field'>
                            <input type="email" name='email' placeholder=' E-mail' onInput={(event) => handleInputChange(event)} required />
                            <input type="password" name='password' placeholder=' Password' onInput={(event) => handleInputChange(event)} required />
                            <input type="password" name='rPassword' placeholder=' Repeat password' onInput={(event) => handleInputChange(event)} required />
                        </div>
                        <div className='main key' onClick={() => {
                            if (!inputData.email) {
                                setError("Enter E-mail")

                            } else if (!inputData.password.length || inputData.password !== inputData.rPassword) {
                                setError("Passwords don't match")

                            } else if (inputData.password.length < 8 || !/[A-Z]/.test(inputData.password)) {
                                setError("Password must be at least 8 characters long and contain at least one uppercase letter")

                            } else {
                                setSignType("register2")
                            }
                        }}>Sign up</div>
                        {/* <div>forgot your password?</div> */}
                        <div className='additional key' onClick={() => setSignType("login")}>already a user? log in</div>
                    </form>
                </div>
            )}
            {signType === "register2" && (
                <div className='wrapper'>
                    <form action="" /* onSubmit={register}  */>
                        {/* <div className='error'>{'Enter your name'}</div> */}
                        <div className="input-title">Enter your name</div>

                        <div className='input-field'>
                            <input type="text" name='name' placeholder=' Fisrt name' onInput={(event) => handleInputChange(event)} required />
                            <input type="text" name='surname' placeholder=' Last name' onInput={(event) => handleInputChange(event)} required />
                        </div>
                        <div className='main key' onClick={(e) => {
                            if (!inputData.name.length || !inputData.surname.length) {
                                setError('Fill out all the fields')
                            } else {

                                register(e)
                            }
                        }
                        }>Continue</div>
                        <div className='additional key' onClick={() => { setSignType("register"); setError('') }}>back</div>
                    </form>
                </div>
            )}
            {signType === "recover" && (
                <div className='wrapper'>
                    <form action="">
                        {/* <div className='error'>{error}</div> */}
                        <div className="input-title">{error}</div>

                        <div className='input-field'>
                            <input type="email" name='email' placeholder=' E-mail' onInput={(event) => handleInputChange(event)} required />
                        </div>



                        {/* <div className="main key" onClick={(e) => { setSignType('recoverCode'); generateNewCode(e) }} > </div> */}

                        <div className='main key' onClick={(e) => {
                            if (!inputData.email) {
                                setError('enter E-mail')
                            } else {

                                generateNewCode(e)
                            }
                        }}
                        >Send Email</div>
                        <div className='additional key' onClick={() => { setSignType("login"); setError('') }}>back to login</div>
                    </form>
                </div>
            )}
            {signType === "code" && (
                <div className='wrapper'>
                    <form action="">
                        {/* <div className='code-title'>{error ? error : "a verification code has been sent check your email!"}</div> */}
                        <input type="text" name='code' placeholder=' Verification code' onInput={(event) => handleInputChange(event)} required />
                        {!stop ? <div className='additional key' onClick={(e) => generateNewCode(e)}>Didn't receive a code?<br></br>resend</div>
                            :
                            <div className='additional key'>Please wait 2 minutes before you can <br></br> ask for new verefication code</div>}
                        <div className='terms'>By pressing “Confirm” <br></br>I agree to <Link>terms of use</Link> and <Link>privacy policy</Link></div>
                        <div className='main key' onClick={verifyEmail}>Confirm</div>
                        <div className='additional key' onClick={() => { setSignType("login"); setError('') }}>back to login</div>
                    </form>
                </div>
            )}
            {signType === 'recoverCode' && (
                <div className='wrapper'>
                    <form action="">
                        {/* <div className='code-title'>{error ? error : "a verification code has been sent check your email!"}</div> */}
                        <input className='recoverCode' type="text" name='code' placeholder='Verification code' onInput={(event) => handleInputChange(event)} required />
                        {!stop ? <div className='additional key' onClick={(e) => generateNewCode(e)}>Didn't receive a code?<br></br>resend</div>
                            :
                            <div className='additional key'>Please wait 2 minutes before you can <br></br> ask for new verefication code</div>}
                        <div className='main key' onClick={() => setSignType('changePass')}>Confirm</div>
                    </form>
                    <div className='additional key' onClick={() => { setSignType("login"); setError('') }}>cancel</div>
                </div>
            )}
            {signType === "changePass" && (
                <div className="wrapper">
                    <form action="">
                        {/* <div className='error'>{error ? error : "Enter new password"}</div> */}
                        <div className="input-title"></div>

                        <div className='input-field'>
                            <input type="password" name='password' placeholder=' Password' onInput={(event) => handleInputChange(event)} required />
                            <input type="password" name='rPassword' placeholder=' Repeat password' onInput={(event) => handleInputChange(event)} required />
                        </div>
                        <div className='main key' onClick={(e) => {
                            if (!inputData.password.length) {
                                setError("Enter password")

                            } else if (inputData.password !== inputData.rPassword) {
                                setError("Passwords dont match")
                            }
                            else {
                                changePassword(e)
                            }
                        }}>Continue</div>
                    </form>
                    <div className='additional key' onClick={() => { setSignType("login"); setError('') }}>cancel</div>
                </div>
            )}
        </div>
    )
}

export default observer(Auth)