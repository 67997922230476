import React, { useState } from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Footer = () => {
    const [featuredRoughLink, setFeaturedRoughLink] = useState('')
    const [featuredPolishedLink, setFeaturedPolishedLink] = useState('')
    useState(() => {
        const fetchFeaturedRoughArticle = async () => {
            const { data } = await axios.get('/article/articles', {
                params: {
                    featured: true,
                    limit: 1,
                    contentType: 'article',
                    type: 'rough'
                }
            })

            setFeaturedRoughLink(data?.at(0).link)
        }
        const fetchFeaturedPolishedArticle = async () => {
            const { data } = await axios.get('/article/articles', {
                params: {
                    featured: true,
                    limit: 1,
                    contentType: 'article',
                    type: 'polished'
                }
            })

            setFeaturedPolishedLink(data?.at(0).link)

        }
        fetchFeaturedPolishedArticle()

        fetchFeaturedRoughArticle()
    }, [])


    return (
        <div className='footer-component'>
            {/* {window.innerWidth > 991 ? */}
            <div className="wrapper">
                <div className="rough">
                    <div className='links'>
                        <Link to={'/articles/rough/latest'}>latest</Link>
                        <Link to={`/article/${featuredRoughLink}`}>featured</Link>
                        <Link to={'/articles/rough/popular'}>popular</Link>
                        <Link to={'/articles/rough/external'}>external</Link>
                        <Link to={'/articles/rough/video'}>videos</Link>
                    </div>
                    <div className='rough-subsection'>
                        <div className="title">Rough</div>
                        <Link to={'/category/food for thought'}>food for thought</Link>
                        <Link to={'/category/market talks'}>market talks</Link>
                        <Link to={'/category/big time'}>big time</Link>
                    </div>
                </div>
                <div className='footer-info'>
                    <div className='logo'> Rough-Polished <span>©  Copyright Rough-Polished 2023. All Rights Reserved.</span></div>
                    <div className='sub-logo'>escape to reality</div>
                    <div className='links'>
                        <div className='link'>
                            <Link to={'/aboutus'}>
                                About us
                            </Link>
                            <Link to={'/termsofuse'}>
                                Terms of Use
                            </Link>
                        </div>
                        <div className="divider">  </div>
                        <div className='link'>
                            <Link to={'/contactus'}>
                                Contact us
                            </Link>
                            <Link to={'/privacypolicy'}>
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="polished">
                    <div className='polished-subsection'>
                        <div className="title">Polished</div>
                        <Link to={'/category/you need to know'}>you need to know</Link>
                        <Link to={'/category/right-hand man'}>right-hand man</Link>
                        <Link to={'/category/gemspedia'}>gemspedia</Link>
                        <Link to={'/category/crystal ball'}>crystal ball</Link>
                        <Link to={'/category/congenial'}>congenial</Link>
                    </div>
                    <div className='links'>
                        <Link to={'/articles/polished/latest'}>latest</Link>
                        <Link to={`/article/${featuredPolishedLink}`}>featured</Link>
                        <Link to={'/articles/polished/popular'}>popular</Link>
                        <Link to={'/articles/polished/external'}>external</Link>
                        <Link to={'/articles/polished/video'}>videos</Link>
                    </div>

                </div>
            </div>

            <div className="wrapper-mobile wrapper">
                <div className="footer-info">
                    <div className='logo'> Rough-Polished <span>©  Copyright Rough-Polished 2023. All Rights Reserved.</span></div>
                    <div className='sub-logo'>escape to reality</div>
                </div>
                <div className="rough-polished">
                    <div className="rough">

                        <div className='rough-subsection'>
                            <div className="title">Rough</div>
                            <Link to={'/category/food for thought'}>food for thought</Link>
                            <Link to={'/category/market talks'}>market talks</Link>
                            <Link to={'/category/big time'}>big time</Link>
                        </div>
                        <div className='links'>
                            <Link to={'/articles/rough/latest'}>latest</Link>
                            <Link to={`/article/${featuredRoughLink}`}>featured</Link>
                            <Link to={'/articles/rough/popular'}>popular</Link>
                            <Link to={'/articles/rough/external'}>external</Link>
                            <Link to={'/articles/rough/video'}>videos</Link>
                        </div>
                    </div>
                    <div className="polished">
                        <div className='polished-subsection'>
                            <div className="title">Polished</div>
                            <Link to={'/category/you need to know'}>you need to know</Link>
                            <Link to={'/category/right-hand man'}>right-hand man</Link>
                            <Link to={'/category/gemspedia'}>gemspedia</Link>
                            <Link to={'/category/crystal ball'}>crystal ball</Link>
                            <Link to={'/category/congenial'}>congenial</Link>
                        </div>
                        <div className='links'>
                            <Link to={'/articles/polished/latest'}>latest</Link>
                            <Link to={`/article/${featuredPolishedLink}`}>featured</Link>
                            <Link to={'/articles/polished/popular'}>popular</Link>
                            <Link to={'/articles/polished/external'}>external</Link>
                            <Link to={'/articles/polished/video'}>videos</Link>
                        </div>

                    </div>
                </div>
                <div className='links'>
                    <div className='link'>
                        <Link to={'/aboutus'}>
                            About us
                        </Link>
                        <Link to={'/termsofuse'}>
                            Terms of Use
                        </Link>
                    </div>
                    <div className="divider">  </div>
                    <div className='link'>
                        <Link to={'/contactus'}>
                            Contact us
                        </Link>
                        <Link to={'/privacypolicy'}>
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>

            {/* } */}
        </div>
    )
}

export default Footer