import { makeAutoObservable, observable } from 'mobx';
import axios from 'axios'
import errorHandlingStore from './errorStore';

class Auth {
    isAuth = false
    userInfo = {}

    constructor() {
        makeAutoObservable(this)
        if (localStorage.token) {
            this.authorize(localStorage.token)
        }
        // errorHandlingStore.setupErrorHandling(this.setError);
        errorHandlingStore.setupErrorHandling();
    }

    authorize(token) {
        axios.defaults.headers.Authorization = token;
        this.isAuth = true;
        localStorage.token = token
    }
    // errorStore.setupErrorHandling(this.setError);


    *getMe() {
        try {
            const { data } = yield axios.get('/auth/me');
            this.userInfo = observable(data);


        } catch (err) {
            // console.log(err.response.status)
            if (err.response.status === 401) {
                this.logout()
            }
        }
    }


    logout() {
        localStorage.token = ''
        this.isAuth = false;
        this.userInfo = {}
    }
}

export default Auth