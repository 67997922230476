import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import List from '../../Components/List/List'

const CategoryList = () => {

    const [articles, setArticles] = useState([])
    const [hasMoreArticles, setHasMoreArticles] = useState(true)
    const { subtype } = useParams()

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const { data } = await axios.get('/article/category', {
                    params: {
                        category: subtype,
                        limit: 6
                    }
                })

                setArticles(data)
            } catch (e) {

            }
        }
        fetchArticles()
    }, [subtype])


    const showMoreArticles = async () => {
        try {
            const lastId = articles.length ? articles.reverse().at(0)._id : null

            const { data } = await axios.get('/article/category', {
                params: {
                    category: subtype,
                    limit: 6,
                    id: lastId
                }
            })
            setArticles((prev) => [...prev.reverse(), ...data])
            setHasMoreArticles(data.length > 0)
        } catch (e) {

        }
    }



    return (
        <div><List articles={articles} hasMoreArticles={hasMoreArticles} showMoreArticles={showMoreArticles} /></div>
    )
}

export default CategoryList