import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import List from '../../Components/List/List'
// import List form '../'
// import List from './Components/

const ArticlesList = () => {
    const { type, content } = useParams()

    const [articles, setArticles] = useState([])
    const [hasMoreArticles, setHasMoreArticles] = useState(true)
    const [page, setPage] = useState(1)


    useEffect(() => {

        const fetchArticles = async () => {
            try {
                const { data } = await axios.get('/article/articleslist', {
                    params: {
                        type,
                        perPage: 10,
                        page,
                        content
                    }
                })

                setArticles(data)
                setPage(prev => prev + 1)
            } catch (e) {

            }
        }
        fetchArticles()
    }, [type, content])


    const showMoreArticles = async () => {
        try {
            setPage(prev => prev + 1)
            const { data } = await axios.get('/article/articleslist', {
                params: {
                    type,
                    perPage: 10,
                    content,
                    page,
                }

            })
            setArticles((prev) => [...prev, ...data])
            setHasMoreArticles(data.length > 0)
        } catch (e) {

        }

    }

    return (
        <div><List articles={articles} hasMoreArticles={hasMoreArticles} showMoreArticles={showMoreArticles} /></div>
    )
}

export default ArticlesList
