import React, { useEffect, useState } from 'react';
import './ErrorModal.scss'
import { observer } from 'mobx-react-lite';
import errorHandlingStore from '../../store/errorStore';

const ErrorModal = ({ isOpen, message }) => {
    const [moveModal, setMoveModal] = useState(false)
    const handleClearError = () => {
        errorHandlingStore.clearError();
        setMoveModal(false)
    };

    useEffect(() => {
        let timer;
        if (isOpen) {
            setTimeout(() => {
                setMoveModal(true)
            }, 10);
            timer = setTimeout(() => {
                handleClearError()
            }, 5000);
        }

        return () => {
            setMoveModal(false)
            clearTimeout(timer);
        };
    }, [isOpen]);




    return (
        isOpen && (
            <div className={`error-modal ${moveModal && "error-modal_active"}`}>
                <div className="modal-content">
                    <p>{message}</p>
                    <button onClick={handleClearError} className="close-button">
                        Close
                    </button>
                </div>
            </div>
        )
    );
};

export default (ErrorModal);