import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useStore } from '../../store';
const ProtectedRoute = ({ element }) => {
    const store = useStore();
    const navigate = useNavigate()

    useEffect(() => {
        if (!store.auth.isAuth) {
            navigate('/')
        } else if (store.auth.userInfo.role && store.auth.userInfo.role !== "publisher") {
            navigate('/')
        }
    }, [store.auth.isAuth, store.auth.userInfo.role, navigate]);

    return element;
};


export default observer(ProtectedRoute)