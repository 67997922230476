import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from '../../store'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { heightCalc, imgPath } from '../../utils'
import './Search.scss'
import moment from 'moment'
import List from '../../Components/List/List'

// const limitArtciles = heightCalc(100)

const Search = () => {
    const [articles, setArticles] = useState([])
    const [hasMoreArticles, setHasMoreArticles] = useState(true)

    const { id } = useParams()



    const fetchArticles = useCallback(async (next) => {
        try {
            let lastId

            if (next) {
                lastId = articles.length ? articles.reverse().at(0)._id : null
            }

            // return
            const { data } = await axios.get('/article/search', {
                params: { title: id, limit: 6, id: lastId, publish: true }

            })
            setArticles(data)

        } catch (e) {

        }
    }, [articles, id])

    useEffect(() => {
        fetchArticles()
    }, [id])

    const showMoreArticles = async () => {
        try {
            const lastId = articles.length ? articles.reverse().at(0)._id : null
            const { data } = await axios.get('/article/search', {
                params: { title: id, limit: 6, id: lastId, publish: true }

            })
            setArticles((prev) => [...prev.reverse(), ...data])
            setHasMoreArticles(data.length > 0)
        } catch (e) { }
    }


    return (
        <div className='search-page'>
            <List articles={articles} hasMoreArticles={hasMoreArticles} showMoreArticles={showMoreArticles} />
        </div>

    )
}

export default observer(Search)

