import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../store';
import { observer } from 'mobx-react-lite';

import './UserSettingsChanges.scss'

const UserSettingsChanges = () => {
    const [inputData, setInputData] = useState({
        email: '',
        password: '',
        rPassword: '',
        code: ''
    });
    const [error, setError] = useState('')
    const [emailStep, setEmailStep] = useState("email")
    const [stop, setStop] = useState(false)

    const store = useStore()
    const { type } = useParams();
    const navigate = useNavigate()

    const changePassword = async (event) => {
        event.preventDefault();
        axios.post('/user/resetpass', {
            email: store.auth.userInfo.email,
            newPassword: inputData.password
        }).then(({ data }) => {

            navigate('/settings')
        }).catch((err) => {
            console.log(err)
        }).finally(() => {

        })
    }

    const preventFunction = () => {
        setTimeout(() => {


            setStop(false)
        }, 120000);
    }



    const generateCode = async (event) => {
        event.preventDefault();
        setStop(true)
        preventFunction()

        axios.post('/user/sendcode', {
            newEmail: inputData.email,
            email: store.auth.userInfo.email
        }).then(({ data }) => {

            setEmailStep('code')

        }).catch((err) => {


            // if (!err) {
            // }

        }).finally(() => {

        })

    }

    const changeEmail = (event) => {
        event.preventDefault();


        axios.post('/user/resetemail', {
            email: store.auth.userInfo.email,
            newEmail: inputData.email,
            code: inputData.code

        }).then(({ data }) => {
            navigate('/settings')

        }).catch((err) => {
            console.log(err)

        })
    }


    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value })
    }

    return (
        <div className='user-settings-changes'>
            <h1>Rough-Polished</h1>
            {type === "email" && (
                <div>
                    {emailStep === "email" &&
                        <div className='wrapper'>
                            <form action="">
                                <div className='error'>{error}</div>
                                <div className='input-field'>
                                    <input type="email" name='email' placeholder=' E-mail' onInput={(event) => handleInputChange(event)} required />
                                </div>




                                <div className='main key' onClick={(e) => {
                                    if (!inputData.email) {
                                        setError('enter E-mail')
                                    } else {
                                        // setEmailStep("code");
                                        generateCode(e)
                                    }
                                }}
                                >Send Email</div>
                                {/* <div className='additional key' onClick={() => { setEmailStep("code"); setError('') }}>back to login</div> */}
                            </form>
                            <Link to={'/settings'} className='additional key' onClick={() => { /* setSignType("login"); */ setError('') }}>back</Link>
                        </div>
                    }
                    {emailStep === "code" &&
                        <div className='wrapper'>
                            <form action="">
                                <div className='code-title'>{error ? error : "a verification code has been sent check your email!"}</div>
                                <input type="text" name='code' placeholder=' Verification code' onInput={(event) => handleInputChange(event)} required />
                                {!stop ? <div className='additional key' onClick={(e) => generateCode(e)}>Didn't receive a code?<br></br>resend</div>
                                    :
                                    <div className='additional key'>Please wait 2 minutes before you can <br></br> ask for new verefication code</div>}
                                <div className='main key' onClick={changeEmail}>Confirm</div>
                                {/* <div className='additional key' onClick={() => { setSignType("login"); setError('') }}>back to login</div> */}
                            </form>
                            <Link to={'/settings'} className='additional key' onClick={() => { /* setSignType("login"); */ setError('') }}>back</Link>
                        </div>
                    }
                </div>
            )}
            {type === "password" && (
                <div className="wrapper">
                    <form action="">
                        <div className='error'>{error ? error : "Enter new password"}</div>
                        <div className='input-field'>
                            <input type="password" name='password' placeholder=' Password' onInput={(event) => handleInputChange(event)} required />
                            <input type="password" name='rPassword' placeholder=' Repeat password' onInput={(event) => handleInputChange(event)} required />
                        </div>
                        <div className='main key' onClick={(e) => {
                            if (!inputData.password.length) {
                                setError("Enter password")

                            } else if (inputData.password !== inputData.rPassword) {
                                setError("Passwords dont match")
                            }
                            else {
                                changePassword(e)
                            }
                        }}>Continue</div>
                    </form>
                    <Link to={'/settings'} className='additional key' onClick={() => { /* setSignType("login"); */ setError('') }}>back</Link>
                </div>
            )}
        </div>
    )
}

export default observer(UserSettingsChanges)