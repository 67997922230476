import axios from 'axios'
import React, { useState } from 'react'
import './AddVideo.scss'

const AddVideo = () => {
    const [type, setType] = useState('');
    const [category, setCategory] = useState('')
    const [onSuccess, setOnSuccess] = useState(false)

    const [formData, setFormData] = useState({
        description: '',
        videoLink: '',
        title: '',
    })


    const handleState = (e) => {
        // console.log(e.target)
        const { name, value } = e.target
        console.log(name)
        setFormData({ ...formData, [name]: value })
    }


    const createVideo = () => {
        axios.post('/article/video', {
            title: formData.title,
            videoLink: formData.videoLink,
            description: formData.description,
            type: type,
            category: category


        }).then(({ data }) => {
            setFormData({
                description: '',
                videoLink: '',
                title: '',
            })
            setType('')
            setCategory('')
            setOnSuccess(true)
        }).catch((err) => {

        })

        setTimeout(() => {
            setOnSuccess(false)
        }, 5000);
    }


    const handleTypeCheckbox = (option) => {
        setType(option);
        // setError(false)
        setCategory('')
    };
    const handleCategoryCheckbox = (option) => {
        setCategory(option)

    }

    return (
        <div className='add-video-page'>
            <h1>Add video</h1>
            <form action="">
                <input type="text" name='title' onInput={(e) => handleState(e)} placeholder='Title' />
                <input type="text" name='videoLink' onInput={(e) => handleState(e)} placeholder='Video link' />
                <input type="text" name='description' onInput={(e) => handleState(e)} placeholder='Description' />
            </form>
            <div className='type'>
                <label>
                    <input
                        type="radio"
                        checked={type === 'rough'}
                        onChange={() => handleTypeCheckbox('rough')}
                    />
                    rough
                </label>
                <label>
                    <input
                        type="radio"
                        checked={type === 'polished'}
                        onChange={() => handleTypeCheckbox('polished')}
                    />
                    polished
                </label>
            </div>
            <div>
                {type === "polished" ?
                    <div>
                        <label><input type='radio' checked={category === "you need to know"} onChange={() => handleCategoryCheckbox("you need to know")} />you need to know</label>
                        <label><input type='radio' checked={category === "right-hand man"} onChange={() => handleCategoryCheckbox("right-hand man")} />right-hand man</label>
                        <label><input type='radio' checked={category === "gemspedia"} onChange={() => handleCategoryCheckbox("gemspedia")} />gemspedia</label>
                        <label><input type='radio' checked={category === "crystall ball"} onChange={() => handleCategoryCheckbox("crystall ball")} />crystall ball</label>
                        <label><input type='radio' checked={category === "congenial"} onChange={() => handleCategoryCheckbox("congenial")} />congenial</label>
                    </div>
                    :
                    <div>
                        <input type='radio' checked={category === "food for thought"} onChange={() => handleCategoryCheckbox("food for thought")} />food for thought
                        <input type='radio' checked={category === "market talks"} onChange={() => handleCategoryCheckbox("market talks")} />market talks
                        <input type='radio' checked={category === "big time"} onChange={() => handleCategoryCheckbox("big time")} />big time
                    </div>
                }
            </div>
            <button onClick={createVideo}>Publish</button>
            {onSuccess && <div className='success'>Article successfully uploaded</div>}
        </div>
    )
}

export default AddVideo