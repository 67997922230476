import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import './ExternalList.scss'
import ErrorPage from '../ErrorPage/ErrorPage';

const ExternalList = () => {
    const { type } = useParams();

    const [articles, setArticles] = useState([])
    const [hasMoreArticles, setHasMoreArticles] = useState(true)
    useEffect(() => {
        const fetchExternal = async () => {
            try {
                const { data } = await axios.get('/article/external', {
                    params: {
                        type: type,
                        limit: 6
                    }
                })

                setArticles(data)
            } catch (e) {

            }
        }
        fetchExternal()
    }, [type])

    const showMoreArticles = async () => {
        try {
            const lastId = articles?.reverse().at(0)?._id
            const { data } = await axios.get('/article/external', {
                params: {
                    type,
                    limit: 2,
                    id: lastId
                }
            })
            setArticles((prev) => [...prev.reverse(), ...data])
            setHasMoreArticles(data.length > 0)
        } catch (e) { }
    }
    return (
        <div className='external-page'>
            {articles?.length ?
                <div className='external-wrapper'>
                    {(articles?.map((item) => (
                        <div key={item._id} className='article' >
                            <div className="article-container">

                                <div className='date'>
                                    {moment(item.createdAt).format("L")}
                                </div>

                                <h1>
                                    {item.title}
                                </h1>

                                <div className='descr'>
                                    {item.description}
                                </div>

                                <a href={`https://${item.link}`}>Read full on {item.link}</a>
                            </div>
                            <div className="divider" />
                        </div>
                    )))}
                    {(articles.length && hasMoreArticles) ? <button className='fetch-articles' onClick={showMoreArticles}>Show more</button> : null}

                </div>
                :
                <div>{<ErrorPage />}</div>
            }
        </div>
    )
}

export default ExternalList