import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { heightCalc, imgPath } from '../../utils'
import './Admin.scss'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'


const limitUsers = heightCalc(60)
const limitArticles = heightCalc(1000)

const Admin = () => {
    const [data, setData] = useState([])
    const [articles, setArticles] = useState([])
    // const [articles, setArticles] = useState([])
    const [articleType, setArticleType] = useState('')


    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')
    // const [searchUsers, setSearchUsers] = (false)
    // const [searchArtciles, setSearchArticles] = (false)

    const inputRef = useRef()

    const store = useStore()




    const getArticles = useCallback(async (type) => {
        // console.log(type)
        setSearch('')
        setUsers('')
        const lastId = articles?.at(0)?._id
        console.log(type)
        const { data } = await axios.get(`/admin/getartcilestoapprove`, {
            params: { id: lastId, contentType: type, publish: false }
        })
        // type === "articles" ? setArticles(data) : setVideos(data)
        setArticles(data)
        console.log(data)
    }, [])

    const updateFeatured = async (type, id) => {
        const { data } = await axios.post(`/admin/updatefeatured`, {
            type: type,
            id: id
        })
    }



    const searchContent = async (event) => {
        event.preventDefault();
        setUsers('')

        if (search === 'article') {
            const lastId = articles.length ? articles.reverse().at(0)._id : null

            const inputValue = inputRef.current.value.length ? inputRef.current.value : ' '


            // return
            const { data } = await axios.get('/article/search', {
                params: { title: inputValue, limit: limitArticles, id: lastId, publish: true }

            })
            setArticles(data)
            console.log(data)

        } else if (search === 'user') {
            const inputValue = inputRef.current.value.length ? inputRef.current.value : ' '
            console.log(inputValue)
            const lastId = users.length ? users.reverse().at(0)._id : null
            const { data } = await axios.get('/admin/getusers', {
                params: { name: inputValue, id: lastId }
            })
            setUsers(data)
            console.log(data)
        }
        // const lastId = articles.length ? articles.reverse().at(0)._id : null
        // const { data } = await axios.get('/article/search', {
        // params: { title: id, limit: 1, id: lastId }
        // })

    }
    const approveArticel = async (id) => {
        const { data } = await axios.patch(`/admin/approvearticle/${id}`)

    }

    const deleteArticel = async (id) => {
        const { data } = await axios.delete(`/admin/deletearticle/${id}`)

    }



    const promote = async (id, role) => {
        console.log(id, role)
        const { data } = await axios.patch(`/admin/promote/${id}`, {
            role
        })
    }

    const banUser = async (id) => {
        const { data } = await axios.patch(`/admin/ban/${id}`)
    }


    const showSearch = (type) => {

        setSearch(type)
        setArticles([])
        setUsers('')

    }






    return (
        <div className="admin-page">
            <div className="header">

            </div>
            <div className="header-content">
                <h1>Admin Panel</h1>


                <div className='logout' onClick={() => store.auth.logout()}> <span className="material-symbols-outlined">
                    logout
                </span>
                    <p>
                        Logout
                    </p>
                </div>
            </div>
            <div className='sidebar'>

                <button className="articles-to-approve" onClick={() => getArticles('article')}>Articles to approve</button>
                <button className='videos-to-approve' onClick={() => getArticles('video')}>Videos to approve</button>
                <button className="search-external" onClick={() => getArticles('external')}>External to approve</button>
                <button className="search-articles" onClick={() => showSearch('article')}>Search articles</button>
                <button className="search-users" onClick={() => showSearch('user')}>Search User</button>
                <div className="divier" />
                <Link to={'/createarticle'}>Create article</Link>
                <Link to={'/createexternal'} >Add external article</Link>
                <Link to={'/addvideo'}>Add video</Link>

            </div>

            <div className="content">
                {/* <Article data={articlesToApprove} /> */}
                {search && (
                    <div>
                        <div>show {search}s</div>
                        <form action="" onSubmit={(event) => searchContent(event)}>

                            <input /* onKeyDown={fe} */ ref={inputRef} />
                            <button>search</button>
                        </form>
                    </div>

                )}
                {articles.map((item) => (
                    <div className='articles' key={item._id}>


                        <div className='articles-container' >
                            <div className='article'>
                                {item.contentType !== 'external' &&
                                    <div>

                                        {item.contentType === 'article' ?
                                            <img src={imgPath(item.thumb)} alt="" />
                                            :
                                            <img src={item.thumb} alt='' />
                                        }
                                    </div>
                                }


                                <div>
                                    <div className='title'>
                                        {item.title}
                                    </div>
                                    <div className='descr'>
                                        {item.description}
                                    </div>
                                    <div className='info'>
                                        {item.type} {item.category}
                                    </div>
                                </div>
                            </div>
                            <div className='article-control'>
                                <Link to={`/article/${item.link}`}>Show full {item.contentType}</Link>
                                {!search && <button onClick={() => approveArticel(item._id)}>approve</button>}
                                <button onClick={() => deleteArticel(item._id)}>delete</button>
                                {search && <button onClick={() => updateFeatured(item.type, item._id)}>featured</button>}
                            </div>

                        </div>


                    </div>
                ))}
                {users && (
                    <div>
                        {users?.map((item) => (
                            <div key={item._id} className='user'>
                                <div className="name">Name: {item.name} {item.surname}</div>
                                <div className='role'>Role: {item.role}</div>
                                <div className='ban-status'>{item.banned ? 'user is banned' : 'user is not banned'}</div>
                                <div className='id'>user id: {item._id}</div>
                                {item.role !== 'user' && <div className='role-toggle' onClick={() => promote(item._id, 'user')}>change user role to user</div>}
                                {item.role !== 'publisher' && <div className='role-toggle' onClick={() => promote(item._id, 'publisher')}>change user role to publisher</div>}
                                {item.role !== 'moder' && <div className='role-toggle' onClick={() => promote(item._id, 'moder')}>change user role to moderator</div>}
                                <div className='ban-toggle' onClick={() => banUser(item._id)}>{item.banned ? "remove ban" : "ban user"}</div>

                            </div>
                        ))}
                    </div>
                )}


            </div>
            {/* <input onChange={(e) => setInputString(e.target.value)} value={inputString} placeholder='Search TypeCode' /> */}
        </div >




    )
}

export default observer(Admin)




