import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { imgPath, } from '../../utils';
import './Category.scss'

const Category = () => {

    const { subtype } = useParams();
    const [articles, setArticles] = useState([])


    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const { data } = await axios.get('/article/category', {
                    params: {
                        category: subtype,
                        limit: 13
                    }
                })

                setArticles(data)
            } catch (e) {

            }
        }
        fetchArticles()
    }, [subtype])

    return (
        <div className='category-page'>
            <div>
                <h1>{subtype}</h1>
                <h2>Latest</h2>
            </div>
            <div className="wrapper">
                {articles.map((item, index) => (
                    // <Link to={`/article/${item.link}`} key={index} className={index === 0 ? "main-article" : index < 3 ? 'sub-article' : 'article'}>
                    <Link
                        to={`/article/${item.link}`}
                        key={index}
                        className={`${index === 0 ? "main-article" : index < 3 ? "sub-article" : "article"
                            }${index === 7 || index === 12 ? " no-right-margin" : ""}`}
                    >
                        <div>
                            {item.contentType === 'article' ?
                                <img src={imgPath(item.thumb)} alt="" />
                                :
                                <img src={item.thumb} alt='' />
                            }
                        </div>
                        <div>
                            {index === 0 ?
                                <div>
                                    <div className='title'>{item.title}</div>
                                    <div className="descr">{item.description}</div>
                                </div>
                                :
                                index === 1 || index === 2 ?
                                    <div>
                                        <div className="title">{item.title}</div>
                                    </div>
                                    :
                                    <div>
                                        <div className='title'>{item.title}</div>
                                    </div>
                            }
                        </div>
                    </Link>

                ))}
            </div>
            <Link to={`/categorylist/${subtype}`}>
                <button>Show all</button>
            </Link>
        </div>
    )
}

export default Category

