import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import axios from 'axios';


axios.defaults.baseURL = (process.env.NODE_ENV === "development" ? 'http://localhost:4444' : document.location.origin) + "/api";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <App />

);

