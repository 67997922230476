import React from 'react'
import { Link, useParams } from 'react-router-dom'
import "./ModerationRules.scss"

const ModerationRules = () => {
  const { article } = useParams()

  return (
    <div className='rules-page'>
      <Link to={`/article/${article}`} className="back">
        <span className="material-symbols-outlined">
          arrow_back_ios
        </span>
        Back
      </Link>
      <p>Rough-Polished values unique opinions. We appreciate your input and welcome distinctive insights and constructive criticism, while encouraging active engagement with our content and discussion with fellow users.</p>

      <p>Our comment section allows you to share and explore different views and is being moderated by the Rough-Polished team. If you wish to contact us in case of the possible inappropriate behaviour of other commenters or appeal our decisions regarding your account, feel free to reach us at <a href="mailto:support@rough-polished.com">support@rough-polished.com</a>.</p>

      <p><strong>Comment section guidelines</strong></p>

      <p>Rough-Polished moderates the comment section in order to keep a healthy and balanced ground for discussions. Before leaving a comment, we strongly recommend that you familiarise yourself with the following guidelines:</p>
      <ul>
        <li><p>Treat others with respect. Avoid personal attacks, hate speech, and offensive language.</p></li>

        <li><p>Do not spam with repetitive messages, links, or advertisements.</p></li>

        <li><p>Stay on topic. Keep your comments relevant to the article or discussion.</p></li>

        <li><p>Excessive remarks regarding our guidelines or moderation.</p></li>

        <li><p>Do not share personal information, such as addresses or phone numbers, in the comments.</p></li>
      </ul>

      <p>Disregarding these guidelines may result in the removal of your comment or account suspension.</p>

    </div>
  )
}

export default ModerationRules