import React from 'react'

const AboutUs = () => {
    return (
        <div className='privacyAndTerms-page about-us'>
            <h1>About us</h1>
            Rough-Polished - first launched in 2007, the project aimed to provide a wide range of readers with maximum information about the rough and polished diamond market.<br></br>
            The past 15 years have been an epoch of degradation of the systematized industry, heir to the world monopoly. Degradation in this particular case does not carry a negative meaning, it only states the fact of a gradual return to the “pre-industrial” era at a new round of technological and social development.<br></br>
            According to the apt expression of one of the market participants - “diamond postmodern”, where there are no clear rules, people start to talk about values in combination with the adjectives “former”, or “leaving”, and the most important task is to attract the momentary attention of the consumer, which means quick money takes the rule, than persistence. The time has come when a new stable structure of the future market will be formed in the hustle and bustle of the Brownian movement.<br></br>
            We want to talk about this and therefore completely changed the entire design of the project. Now we are considering the diamond market along with the development of consumption of all precious and semi-precious stones. We will pay enough attention to the study of global factors influencing the formation of a new concept of "precious", and, accordingly, the businesses that will serve it.<br></br>
            News means market inside, not repost and opinions - the knowledge of a direct market participant. The website will also provide technical options for your comments.
            And of course, we will try to offer maximum expert information to the reader, who may wish to turn interest in the stone into possession of it.

        </div>
    )
}

export default AboutUs