
import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const TextEditor = ({ onChange, value }) => {
    const handleChange = (content) => {
        onChange(content);
    };





    return (
        <SunEditor
            setOptions={{
                defaultStyle: 'font-family: Raleway, sans-serif',
                buttonList: [
                    ['undo', 'redo'],
                    ['fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['removeFormat'],
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video'],
                    ['fullScreen', 'showBlocks', 'codeView'],
                    ['preview', 'print'],
                ],


            }}
            onChange={handleChange}
            setContents={value}
        />
    );
};

export default TextEditor;
