
import { useEffect, } from 'react';
import './App.scss'
import { BrowserRouter, Routes, Route, Navigate, } from 'react-router-dom';
import Auth from './Pages/Auth/Auth';
import { useStore } from './store';
import { observer } from 'mobx-react-lite';
import Header from './Components/Header/Haeder'
import Main from './Pages/Main/Main';
import Footer from './Components/Footer/Footer';
import CreateArticle from './Pages/CreateArticle/CreateArticle';
import Article from './Pages/Article/Article';
import Admin from './Pages/Admin/Admin';

import Search from './Pages/Search/Search';
import AddVideo from './Pages/AddVideo/AddVideo';

import ArticlesList from './Pages/ArticlesList/ArticlesList';
import Category from './Pages/Category/Category';
import CategoryList from './Pages/CategoryList/CategoryList';
import CreateExternalArticle from './Pages/CreateExternalArticle/CreateExternalArticle';
import UserSettings from './Pages/UserSettigns/UserSettings';
import ProtectedRoute from './Pages/ProtectedRoute/ProtectedRoute';
import ExternalList from './Pages/ExternalList/ExternalList';
import UserSettingsChanges from './Pages/UserSettingsChanges/UserSettingsChanges';

import ErrorModal from './Components/ErrorModal/ErrorModal';
import TermsOfUse from './Pages/PrivacyAndTerms/TermsOfUse';
import PrivacyPolicy from './Pages/PrivacyAndTerms/PrivacyPolicy';
import AboutUs from './Pages/PrivacyAndTerms/AboutUs';

import ModerationRules from './Pages/Article/ModerationRules';
import errorHandlingStore from './store/errorStore';
import ContactUs from './Pages/PrivacyAndTerms/ContactUs';
import ErrorPage from './Pages/ErrorPage/ErrorPage';

function App() {
  const store = useStore()

  const { error } = errorHandlingStore;




  useEffect(() => {
    if (store.auth.isAuth) {
      store.auth.getMe()
    }

  }, [store.auth])


  return (
    <BrowserRouter>
      {store.auth.userInfo.role !== "admin" && < Header />}
      <div className='App'>
        {store.auth.userInfo.role === "admin" ? (
          <Routes>
            {/* <Route path='/' element={<Navigate to='/auth' />} />
            <Route path='/auth' element={<Auth />} /> */}
            <Route path='/auth' element={<Auth />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/createarticle' element={<CreateArticle />} />
            <Route path='/addvideo' element={<AddVideo />} />
            <Route path='/createexternal' element={<CreateExternalArticle />} />
            <Route path='/article/:link' element={<Article />} />

            <Route path='/*' element={<Navigate to='/admin' />} />
          </Routes>
        ) : (
          <>

            <Routes>
              <Route path='/' element={<Main />} />
              {!store.auth.isAuth && <Route path='/auth' element={<Auth />} />}
              {store.auth.isAuth && <Route path='/settings' element={<UserSettings />} />}
              <Route path='/addvideo' element={<ProtectedRoute element={<AddVideo />} />} />
              <Route path='/createarticle' element={<ProtectedRoute element={<CreateArticle />} />} />
              <Route path='/createexternal' element={<ProtectedRoute element={<CreateExternalArticle />} />} />
              <Route path='/termsofuse' element={<TermsOfUse />} />
              <Route path='/privacypolicy' element={<PrivacyPolicy />} />
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path='/contactus' element={<ContactUs />} />
              {/* <Route path='/error' element={<ErrorPage />} /> */}


              <Route path='/:article/rules' element={<ModerationRules />} />
              <Route path='/articles/:type/:content' element={<ArticlesList />} />
              <Route path='/category/:subtype' element={<Category />} />
              <Route path='/categorylist/:subtype' element={<CategoryList />} />
              <Route path='/settings/:type' element={<UserSettingsChanges />} />
              <Route path='externallist/:type' element={<ExternalList />} />
              <Route path='/article/:link' element={<Article />} />
              <Route path='/search/:id' element={<Search />} />
              <Route path='/*' element={<Navigate to='/' />} />
            </Routes>
            {/* <ErrorModal isOpen={!!error} message={error} onClose={() => setError(null)} /> */}
            <ErrorModal isOpen={!!error} message={error} />

          </>
        )}

      </div>
      {store.auth.userInfo.role !== "admin" && <Footer />}

    </BrowserRouter>
  )
}

export default observer(App);



