import React, { useEffect, useState } from 'react'
import './Header.scss'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import SearchBar from '../SearchBar/Search'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'

const Haeder = () => {
    const store = useStore()
    const navigate = useNavigate();
    const [title, setTitle] = useState("")
    const [isSearch, setIsSearch] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const handleInputChange = (event) => {
        setTitle(event.target.value);
    };

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            navigate(`/search/${title}`);
            setTitle('')
        }
    };

    useEffect(() => {
        setTitle('')
        setShowMobileMenu(false)
    }, [navigate])

    const handleHamburgerMenu = () => {
        setShowMobileMenu(prev => !prev)
    }

    // useEffect(() => {
    //     const bodyOverflow = showMobileMenu ? 'hidden' : '';
    //     // document.body.style.overflow = bodyOverflow;
    //     document.documentElement.style.overflow = bodyOverflow;
    //     document.body.style.overflow = bodyOverflow
    // }, [showMobileMenu])


    return (
        <div className='header-component'>
            <div className='wrapper'>
                <div className='container'>
                    <Link to={'/'} className='logo-container'>
                        <div className="logo">
                            Rough<span>-Polished</span>
                        </div>
                        <div className='sub-logo'>escape to reality</div>
                    </Link >
                    <div className={`hamburger ${showMobileMenu ? "hamburger_active" : ''}`} onClick={handleHamburgerMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <div className='service-menu'>
                        <div className='search' >
                            {!isSearch && <div className='search-cap' onClick={() => setIsSearch(prev => !prev)}>Search</div>}
                            {isSearch &&
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleInputChange}
                                    onKeyDown={handleEnterPress}
                                    placeholder='Search'
                                />
                            }
                            <span className="material-symbols-outlined" onClick={() => setIsSearch(prev => !prev)}>
                                search
                            </span>
                        </div>
                        {/* <button onClick={() => setTrues(true)}></button> */}
                        {store.auth.isAuth ?
                            <Link to={'/settings'} className='avatar'>
                                <div className="avatar-border">
                                    <span>
                                        {store.auth.userInfo.name?.charAt(0)}
                                        {store.auth.userInfo.surname?.charAt(0)}
                                    </span>
                                </div>
                            </Link>
                            :
                            <Link to={'/auth'} className='login'>log in</Link>
                        }

                    </div>
                </div>
                <div className='links'>
                    <Link to={'/category/food for thought'}>food for thought</Link>
                    <div className='divider' />
                    <Link to={'/category/market talks'}>market talks</Link>
                    <div className='divider' />
                    <Link to={'/category/big time'}>big time</Link>
                    <div className='divider main' />
                    <Link to={'/category/you need to know'}>you need to know</Link>
                    <div className='divider' />
                    <Link to={'/category/right-hand man'}>right-hand man</Link>
                    <div className='divider' />
                    <Link to={'/category/gemspedia'}>gemspedia</Link>
                    <div className='divider' />
                    <Link to={'/category/crystall ball'}>crystall ball</Link>
                    <div className='divider' />
                    <Link to={'/category/congenial'}>congenial</Link>
                </div>
                <div className={`menu ${showMobileMenu ? "menu_active" : ''}`} >
                    <input
                        type="text"
                        value={title}
                        onChange={handleInputChange}
                        onKeyDown={handleEnterPress}
                        placeholder='Search'
                    />
                    <Link to={'/category/food for thought'}>food for thought</Link>
                    <Link to={'/category/market talks'}>market talks</Link>
                    <Link to={'/category/big time'}>big time</Link>
                    <div className="divider" />
                    <Link to={'/category/you need to know'}>you need to know</Link>
                    <Link to={'/category/right-hand man'}>right-hand man</Link>
                    <Link to={'/category/gemspedia'}>gemspedia</Link>
                    <Link to={'/category/crystall ball'}>crystall ball</Link>
                    <Link to={'/category/congenial'}>congenial</Link>
                    {store.auth.isAuth ?
                        <Link to={'/settings'} className='avatar'>
                            {store.auth.userInfo.name} {store.auth.userInfo.surname}
                        </Link>
                        :
                        <Link to={'/auth'} className='login'>log in</Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Haeder)