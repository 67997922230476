import auth from './auth'
import article from './artcile'



export const RootStore = {
    auth: new auth(),
    article: new article(),

}

export const useStore = () => RootStore