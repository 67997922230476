import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './Article.scss'
import moment from "moment";
import { autoGrow, imgPath } from '../../utils';
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store';
import Popup from '../../Components/Popup/Popup';


const Article = () => {
    const { link } = useParams()
    const [article, setArticle] = useState('')
    const [comments, setComments] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [text, setText] = useState('')
    const [hasMoreComments, setHasMoreComments] = useState(true)
    const [deleteCommentId, setDeleteCommentId] = useState('')

    const textareaRef = useRef()
    const store = useStore()

    const fetchArticle = async () => {
        try {
            const { data } = await axios.get(`/article/${link}`)
            setArticle(data)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchComments = async () => {
        try {
            const { data } = await axios.get(`/article/${article._id}/comments`, {
            })
            setComments(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchArticle()
    }, [link])

    useEffect(() => {
        if (article._id) {
            fetchComments()
        }
    }, [article?._id])



    const addComment = async (event) => {
        if (event.key === "Enter" || event.type === "click") {
            if (event.key === "Enter") {
                event.preventDefault();
            }

            try {
                const inputValue = textareaRef.current.value;
                if (inputValue.length < 1) {
                    event.target.classList.add('invalid-input');
                    return;
                }

                const { data } = await axios.post(`/article/${article._id}/comments`, {
                    text: inputValue
                });

                setComments((prev) => [data, ...prev]);
                event.target.value = '';
                setText('');
            } catch (e) {
                console.log(e);
            }
        }
    };


    const showMoreComment = async () => {
        try {
            const lastId = comments.length ? comments.reverse().at(0)._id : null
            const { data } = await axios.get(`/article/${article._id}/comments`, {
                params: { id: lastId }
            })
            setComments((prev) => [...prev.reverse(), ...data])
            setHasMoreComments(data.length > 0)
        } catch (e) {
            console.log(e)
        }
    }

    const handleTextCnahge = (e) => {
        const newText = e.target.value;
        setText(newText)
    }

    const remainingCharacters = 500 - text.length;



    const deleteComment = async (_id) => {
        try {
            setComments(prev => {
                return prev.filter(item => item._id !== deleteCommentId)
            })

            await axios.delete(`/article/${deleteCommentId}/comments`)

        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {


        const bodyOverFlow = showPopup ? "hidden" : "auto"

        document.body.style.overflow = bodyOverFlow


    }, [showPopup])


    return (
        <>
            {article?.contentType === "article" ?
                <div className='article-page'>
                    <h1>{article.title}</h1>
                    <div className="info">
                        <div className="date">{moment(article.createdAt).format("MMMM DD, YYYY")}</div>
                        {article.showName &&
                            <div>
                                {article.customCreatorName ?
                                    <div className="name"> {article.customCreatorName} {article.customCreatorSurname}</div>
                                    :
                                    <div className="name"> {article.creator?.name} {article.creator?.surname}</div>
                                }
                            </div>
                        }
                    </div>
                    <div className='main-image'>
                        <img src={`${imgPath(article.mainImage)}`} alt="" />
                    </div>
                    <div className='content' dangerouslySetInnerHTML={{ __html: article.content }}></div>
                    <div className='category'>{article.type} <div className='divider' /> {article.category}</div>
                </div>
                :
                <div className='video-page'>
                    <h1>{article.title}</h1>
                    <div className="info">
                        <div className="date">{moment(article.createdAt).format("MMMM DD, YYYY")}</div>
                        <div className="name">{article.creator?.name} {article.creator?.surname}</div>
                    </div>
                    <iframe src={`https://www.youtube.com/embed/${article.content}`}
                        allowFullScreen
                        title='video'
                    />
                    <div className="descr">
                        {article.description}
                    </div>
                    <div className='category'>{article.type} <div className='divider' /> {article.category}</div>
                </div>

            }
            <div className='comments'>
                {(!comments.length && !store.auth.isAuth) ? null : <div className='comments-title'>Comments</div>}
                {/* <div className='comments-title'>Comments</div> */}
                {store.auth.isAuth &&

                    <div className='textarea-head'>
                        <div className='avatar'>
                            <div className="avatar-border">
                                <span>
                                    {store.auth.userInfo.name?.charAt(0)}
                                    {store.auth.userInfo.surname?.charAt(0)}
                                </span>
                            </div>
                        </div>
                        <div className='textarea-container'>
                            <div className='title'>We appreciate your opinion. Please keep discussions decent and polite.</div>
                            <Link to={`/${link}/rules`} className='subtitle'>Comments are moderated for civility</Link>
                            <textarea
                                type='text'
                                onKeyDown={(event) => addComment(event)}
                                onChange={handleTextCnahge}
                                placeholder='Share your thoughts.'
                                onInput={(event) => autoGrow(event.target)}
                                ref={textareaRef}
                                maxLength={500}
                                required
                            />
                            <div className='live-characters'>{remainingCharacters} characters left</div>
                            <button className='post-comment' onClick={addComment}>Post</button>
                        </div>
                    </div>
                }
                {showPopup && <Popup id={deleteCommentId} deleteComment={deleteComment} setShowPopup={setShowPopup} />}
                <div className='comment-container'>
                    {comments.map((item) => (
                        <div className='comment' key={item._id}>
                            <div className='avatar'>
                                <div className="avatar-border">
                                    <span>
                                        {item.creator?.name?.charAt(0)}
                                        {item.creator?.surname?.charAt(0)}
                                    </span>
                                </div>
                            </div>
                            <div className='comment-details'>
                                <div className='item-details'>
                                    <div className='user-details'>
                                        <div className='role'>{(item?.creator?.role === "admin" || item?.creator?.role === "moder") && <div>{item?.creator?.role}</div>}</div>
                                        <div className='name'>{item?.creator?.name} {item?.creator?.surname}</div>
                                    </div>
                                    <div className='date'>{moment(item.createdAt).fromNow()}</div>
                                    {(store.auth.userInfo._id === item?.creator?._id || store.auth?.userInfo?.role === "admin" || store?.auth?.userInfo.role === "moder") &&
                                        <button className='delete-comment' onClick={() => { setShowPopup(true); setDeleteCommentId(item._id) }}><span className="material-symbols-outlined">
                                            delete_forever
                                        </span></button>
                                    }
                                </div>
                                <div className='content'>{item.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {(comments.length && hasMoreComments) ? <button className='fetch-comments' onClick={showMoreComment}>Show more</button> : null}
            </div>

        </>
    )
}

export default observer(Article)