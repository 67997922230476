import React from 'react'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MulitCarousel.scss'
import { Link } from 'react-router-dom';
import { imgPath, textCutter } from '../../utils';

const MulitCarousel = ({ carouselData }) => {

    // console.log(carouselData[0]._id)

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <span className="material-symbols-outlined out">
                    chevron_left
                    <span className="material-symbols-outlined in">
                        chevron_left
                    </span>
                </span>
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            >
                <span className="material-symbols-outlined out">
                    chevron_right
                    <span className="material-symbols-outlined in">
                        chevron_right
                    </span>
                </span>

            </div>
        );
    };

    const settings = {
        autoplay: true,
        autoplaySpeed: 10000,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        // centerMode: true,
        // variableWidth: window.innerWidth > 1200 ? true : false,
        variableWidth: true,

        responsive: [

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },

            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                    dots: true,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 446,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    variableWidth: false,
                }
            }
        ],
    };



    return (
        <div className='carousel-component'>
            <Slider {...settings}>
                {carouselData.map((item, index) => (
                    <div key={index}>
                        <div className='wrapper'>
                            {/* <div className='descr'>{item.description.length >= 52 ? item.description.substring(0, 52) + "..." : item.description}</div> */}
                            <div className='descr'>{item.description}</div>
                            <div className='image'><img src={imgPath(item.thumb)} alt={`${item.title} ${index + 1}`} /></div>
                        </div>
                        <Link className='title' to={`/article/${item.link}`}>{item.title}</Link>
                        <Link className='mobile-descr' to={`/article/${item.link}`}>{item.description}</Link>

                        {/* <Link to={`/article/${item.link}`}>{item.title.length >= 50 ? item.title.substring(0, 50) + "..." : item.title}</Link> */}
                    </div>
                ))}
            </Slider>
        </div>
    );
}
export default MulitCarousel

// {image.description.length >= 40 ? image.description.substring(0, 40) + "..." : image.description}