
// import errorStore from './ErrorStore';
export const imgPath = (img) => {
    // return `http://localhost:4444/img/${img}`
    return process.env.NODE_ENV === "development" ? `http://localhost:4444/img/${img}` : `/img/${img}`

}


export const heightCalc = (height) => {
    return Math.floor(window.innerHeight / height * 1.3)
}

export const autoGrow = (textarea) => {

    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
}

export const textCutter = (string, length) => {
    return string.length >= length ? string.substring(0, length) : string
}

// { item.title.length >= 50 ? item.title.substring(0, 50) + "..." : item.title }




